<template>
    <div class="collection">
        <collection-top
            :allTypes="['PRODUCT', 'VENDOR']"
            :type.sync="type"
            :searchKey.sync="searchKey"
            @submitSearch="submitSearch"
            :style="{ marginLeft: left + 'px' }"
        >
            <template slot="right">
                <el-button type="info" size="small" plain v-if="!isEdit" @click="isEdit = true">
                    {{ $t('bian-ji') }}
                </el-button>
                <template v-else>
                    <el-button type="info" size="small" plain @click="brantch" v-if="chooseList.length > 0">
                        {{ $t('qu-xiao-shou-cang') }}
                    </el-button>
                    <el-button type="info" size="small" plain @click="isEdit = false">
                        {{ $t('wan-cheng-bian-ji') }}
                    </el-button>
                </template>
            </template>
        </collection-top>

        <div class="el-main" v-loading="loading" :style="{ paddingLeft: left + 'px' }">
            <div class="mainList" ref="list">
                <el-row :gutter="30" type="flex">
                    <el-col v-for="item in list" :key="item.id">
                        <product-gride
                            :info="item"
                            :isNew="false"
                            shadow
                            :hasChoose="isEdit"
                            :chooseList.sync="chooseList"
                            v-if="type === 'PRODUCT'"
                        ></product-gride>

                        <brand-gride
                            :info="item"
                            shadow
                            :hasChoose="isEdit"
                            :chooseList.sync="chooseList"
                            v-else
                        ></brand-gride>
                    </el-col>
                </el-row>
            </div>
            <empty-page v-if="empty">
                <img slot="img" :src="require('@/assets/kong_img_default.png')" />
                <span slot="text">{{$t('zan-shi-mei-you-shou-cango')}}</span>
            </empty-page>
        </div>
        <el-footer>
            <el-pagination
                @size-change="onSizeChange"
                @current-change="onCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalElements"
            >
            </el-pagination>
        </el-footer>
    </div>
</template>
<script>
import pageableList from '../../mixins/pageableList';
import CollectionTop from '../../components/collection/Top';
import ProductGride from '../../components/product/Gride';
import BrandGride from '../../components/brand/Gride';
import { collectType } from '../../utils/AppState';

export default {
    name: 'collection',
    mixins: [pageableList],
    data() {
        return {
            url: '/collect/my',
            httpType: 'get',
            type: 'PRODUCT',
            chooseList: [],
            isEdit: false,
            left: 0
        };
    },
    computed: {
        listQuery() {
            return {
                type: this.type,
                isCollect: true,
                del: false
            };
        },
        typeName() {
            return collectType.get(this.type);
        }
    },
    watch: {
        listQuery() {
            this.page=1;
            this.getData();
        },
        type() {
            this.isEdit = false;
            this.chooseList = [];
        }
        // loading() {
        //     if (this.empty || this.loading) {
        //         this.$nextTick(() => {
        //             this.$el.parentNode.style.marginLeft = '20px';
        //             this.left = 0;
        //         });
        //     } else {
        //         this.$nextTick(() => {
        //             this.$el.parentNode.style.marginLeft = '0px';
        //             this.left = 20;
        //         });
        //     }
        // }
    },
    mounted() {
        this.getData();
    },
    // beforeRouteLeave(from, to, next) {
    //     this.$el.parentNode.style.marginLeft = '20px';

    //     next();
    // },
    methods: {
        brantch() {
            this.$http
                .post('/collect/batchCancel', {
                    type: this.type,
                    ids: this.chooseList.join(','),
                    isCollect: true
                })
                .then(res => {
                    this.$message.success(this.$t('shan-chu-cheng-gong'));
                    this.getData();
                })
                .catch(e => {
                    this.$message.warning(e.error);
                });
        }
    },
    components: {
        CollectionTop,
        ProductGride,
        BrandGride
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/pagebleTable';
@import '../../styles/variables';

.route {
    background-color: transparent !important;
}

// .top {
//     margin-left: 20px;
// }

.el-main {
    margin: 20px 0 0;
    // margin: 0;
    // padding: 25px 0 0;
    // min-height: calc(max(100vh, 1000px) - 136px);

    @include max-height(136px, $max: 1200px);
    overflow: hidden;
    // margin-left: -10px;
}
</style>
